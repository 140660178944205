@import "src/assets/sass/_variables.scss";

.upload-file-area {
    padding: 15px;
    border-radius: 8px;
    border: 2px dashed grey;

    .dropzone-text {
        display: flex;

        > span {
            font-size: em(12);
            line-height: 18px;

            .font-size-large & {
                font-size: em(14);
            }
        }

        + .dropzone-file-list {
            margin-top: 25px;
        }
    }

    .upload-link {
        display: inline-block;
        margin-left: 3px;
        cursor: pointer;

        input[type="file"] {
            display: none;

            + label {
                display: block;
                cursor: pointer;
            }
        }
    }

    .dropzone-file-list {
        margin-left: 5px;

        li {
            display: flex;
            align-items: center;
        }
    }

    &.file-upload-disabled {
        border-color: $light-grey;
        pointer-events: none;

        .dropzone-text {
            > span {
                color: $light-grey;
            }

            .upload-link,
            .icon-wrap:before {
                color: $light-grey;
            }
        }
    }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.data-grid-paging {
    --pagination-background-color: white;
    --pagination-background-color-hover: var(--btn-primary-color-mouseOver);
    --pagination-background-color-active: var(--btn-primary-color-default);
    --pagination-background-color-disabled: transparent;

    --pagination-color: var(--link-default-color);
    --pagination-color-hover: var(--btn-primary-text-color-mouseOver);
    --pagination-color-active: var(--btn-primary-text-color-default);
    --pagination-color-disabled: var(--btn-primary-text-color-disabled);

    --pagination-border-color: var(--bs-gray-300);
    --pagination-border-color-hover: var(--btn-primary-color-mouseOver);
    --pagination-border-color-active: var(--btn-primary-color-default);
    --pagination-border-color-disabled: transparent;

    .page-input {
        width: 6.25rem;
        padding-right: 1.75rem;
    }

    .page-item {
        .page-link {
            background-color: var(--pagination-background-color);
            border-color: var(--pagination-border-color);
            color: var(--pagination-color);

            &:hover {
                background-color: var(--pagination-background-color-hover);
                color: var(--pagination-color-hover);
            }
        }

        &.active {
            .page-link {
                background-color: var(--pagination-background-color-active);
                border-color: var(--pagination-border-color-active);
                color: var(--pagination-color-active);
            }
        }

        &.disabled {
            .page-link {
                opacity: 0.2;
            }
        }

        &.wide-title {
            .page-link {
                padding-right: map-get($spacers, 2);
                padding-left: map-get($spacers, 2);
            }
        }
    }
}

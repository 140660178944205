.date-range-toggle.dropdown-toggle::after {
    display: none;
}

.rdp-vertical {
    .rdp-months {
        flex-direction: column;
        gap: 0.5rem;
    }
}

.offcanvas {
    .date-range-toggle {
        font-size: var(--bs-body-font-size);
    }
}

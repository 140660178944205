@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "src/assets/sass/_variables.scss";

.navbar.header-navigation-widget {
    .ignore-padding-on-mobile {
        @include media-breakpoint-down(md) {
            padding: 0 !important;
        }
    }

    .navbar-collapse {
        width: 100%;
    }

    .nav-item-ul-wrapper {
        width: 100%;
        .menu-item-hover:hover {
            color: var(--hover-color) !important;
        }
    }

    .external-link-icon {
        margin: auto;
        font-size: 14px;
    }

    @include media-breakpoint-down(md) {
        position: static;
        width: 100%;
        height: 100%;
    }

    .navbar-toggler {
        padding: 0.5rem 0.75rem;
        border: none;

        @include media-breakpoint-down(md) {
            position: absolute;
            right: 0.3rem;
            top: 0.7rem;
        }

        &:focus,
        &:focus-visible {
            outline: 0 !important;
            box-shadow: none !important;
        }
    }

    .navbar-collapse {
        @include media-breakpoint-up(md) {
            .nav-item {
                &.limit-max-width {
                    max-width: 25%;
                }
            }
        }
        @include media-breakpoint-down(md) {
            .nav-item {
                width: 100%;
            }

            .nav-link {
                padding: 9px 0;
                border-top: 1px solid;
                border-color: var(--header-navigation-link-border-color);
                font-size: 14px !important;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.layout-row {
    @include media-breakpoint-down(md) {
        padding: 0 !important;
        position: relative;
    }
}

.layout-column {
    @include media-breakpoint-down(md) {
        padding: 0.75rem !important;

        &:not(.row-contains-image) {
            .header-navigation-widget .navbar-nav {
                margin-top: 45px;
            }

            &.row-contains-header-navigation-widget {
                min-height: 70px; // Min-height must be tall enough to contain hamburger menu icon
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .mimic-bootstrap-gutters {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "src/assets/sass/_variables.scss";

.lead-image-wrapper {
    display: grid;
    width: 100%;

    &.image-fit {
        .lead-image {
            grid-area: 1 / 1 / 2 / 2;
            width: 100%;
        }
    }

    &.image-cover,
    &.image-auto {
        min-height: 360px;
        position: relative;
        .lead-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }

    &.image-cover {
        .lead-image {
            object-fit: cover;
        }
    }

    &.image-auto {
        .lead-image {
            object-fit: none;
        }
    }

    .lead-image-content-container {
        z-index: 2;
        grid-area: 1 / 1 / 2 / 2;
    }

    .lead-image-content {
        height: 100%;

        .content-title {
            font-size: 48px;
            max-width: 770px;

            @include media-breakpoint-down(md) {
                font-size: 36px;
            }
        }

        .content-text {
            font-size: 24px;
            line-height: 28px;
            margin-top: 20px;
            max-width: 770px;

            @include media-breakpoint-down(md) {
                font-size: 20px;
                line-height: initial;
            }
        }

        .content-button {
            margin-top: 30px;
        }
    }

    .lead-image-overlay {
        z-index: 1;
        grid-area: 1 / 1 / 2 / 2;
    }

    .image-placeholder {
        height: 360px !important;
    }
}

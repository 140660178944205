@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.app-forms-empty-state {
    .icon {
        width: 2.5rem;
        height: 2.5rem;
    }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.workflow-task {
  background-color: #fff;

  &:hover {
    background-color: var(--bs-gray-100);
  }

  .text-step {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .detail {
    background-color: var(--bs-purple);
    width: 8px;
    border-radius: 4px 0 0 4px;

    &.complete {
      background-color: var(--bs-green);
    }
  }
}

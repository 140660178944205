@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.header-menu {
    z-index: 1;
    background-color: #101223;
    color: #fff;

    .menu-link {
        color: #fff;

        &.home-link svg {
            margin-right: 5px;
        }
    }

    .header-menu-links {
        position: relative;
        font-size: 14px;
        height: 40px;
        background-color: #101223;

        .quick-links-btn {
            margin-right: calc(var(--bs-gutter-x) * -0.5);
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            cursor: pointer;

            svg {
                margin-left: 5px;
            }

            &.expanded {
                position: relative;
                background-color: #353645;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -40px;
                    width: 0;
                    height: 0;
                    border-left: 40px solid transparent;
                    border-bottom: 40px solid #353645;
                }
            }
        }
    }

    .header-quick-links {
        font-size: 14px;
        min-height: 40px;

        @include media-breakpoint-down(md) {
            background-color: #353645;
        }

        .quick-link-item {
            height: 40px;

            &:not(:first-child) {
                border-top: 1px solid rgba(255, 255, 255, 0.15);
            }

            span {
                + svg {
                    margin-left: 5px;
                }
            }
        }
    }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.submitted-app-contact-card {
    box-shadow: 0 5px 6px 0 var(--grid-shadow-color);
    border-radius: 4px;

    .contact-title {
        font-size: 1rem;
        font-weight: 500;
    }

    dt {
        font-size: 0.875rem;
        color: var(--label-color);
    }

    .contact-list-item {
        font-size: 1rem;
        @include media-breakpoint-up(md) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

.contact-card.desktop-header {
    max-width: 375px;
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.carousel-widget {
    .carousel-image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .image-cover {
        object-fit: cover;
    }

    .image-auto {
        object-fit: none;
    }
    .carousel-caption {
        @include media-breakpoint-down(md) {
            right: 0;
            left: 0;
        }
    }
    &.indicators-dark {
        .carousel-indicators button {
            background-color: var(--bs-dark);
        }
    }
}

.text-widget {
    &.text-opacity {
        opacity: 0.5;
    }

    .table-responsive {
        max-width: calc(100vw - 30px);
    }

    // Allow to resize images added to text widget.
    > img,
    :not(td) > img {
        max-width: 100%;
    }
}

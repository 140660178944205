@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.workflow-tasks {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(480px, 1fr)); 


  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
  }

  .container {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 636px;
    }
  }
}

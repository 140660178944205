@import "src/assets/sass/_variables.scss";

.image-placeholder {
    .image-placeholder-text {
        color: $light-grey;
        background-color: white;
        text-transform: uppercase;
    }

    min-height: 60px;
    border: 1px solid $light-grey;
    @supports (-moz-appearance: none) {
        background: linear-gradient(
                to top left,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(49.6%),
                #b7b7b7 50%,
                rgba(0, 0, 0, 0) calc(50%),
                rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(
                to top right,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(49.6%),
                #b7b7b7 50%,
                rgba(0, 0, 0, 0) calc(50%),
                rgba(0, 0, 0, 0) 100%
            );
    }
    background: linear-gradient(
            to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            $light-grey 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%
        ),
        linear-gradient(
            to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            $light-grey 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%
        );
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.cookies-banner {
    min-height: 80px;
    background-color: rgba(31, 31, 31, 0.9);
    color: white;
    padding: 20px 0;

    .cookies-content {
        font-size: 14px;
    }

    &--overlay {
        position: fixed;
        left: 0;
        right: 0;
        z-index: $zindex-offcanvas;
    }

    &--top {
        top: 0;
    }

    &--bottom {
        bottom: 0;
    }
}

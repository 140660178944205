@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.customer-home-v2 {
    background: var(--bs-gray-100, #f7f7f9);
}

#root table.dashboard-applications-listv2 {
    box-shadow: none;

    .button-divider {
        min-width: 1px;
    }

    .app-block-mobile-link {
        padding: 0.375rem 0;
    }

    thead > tr > th {
        box-shadow: none;
        vertical-align: middle;
    }

    @include media-breakpoint-down(md) {
        tbody {
            tr {
                border: var(--bs-card-border-width) solid;
                box-shadow: none;
            }
        }
    }

    tbody > tr > td {
        font-size: 1rem;
    }

    tbody > tr {
        margin-top: 0;
    }
}

.dashboard-notifications-counter {
    margin-left: calc(var(--bs-body-font-size) * -1.5);
    z-index: 1;
}

.notifications-dropdown {
    .modal-dialog {
        max-width: 100%;
    }

    .notifications-toggle::after {
        display: none;
    }

    .modal-content {
        height: 555px;
        background-color: var(--bs-white);

        @include media-breakpoint-down(md) {
            border-radius: 0;
        }
    }

    .notifications-menu {
        z-index: 1070;
        width: 500px;
        height: 555px;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        .nav-bar {
            border-bottom: 1px solid var(--bs-gray-300);
        }

        .nav-link {
            border: none;
            &.active {
                background-color: var(--bs-white);
                border-bottom: 2px solid var(--bs-body-color);
            }
        }

        .tasks,
        .messages {
            height: 400px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .message,
        .task {
            cursor: pointer;

            &:hover {
                background-color: var(--bs-gray-100) !important;
            }

            .content {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-width: 75ch;
            }
        }
    }

    h3 {
        font-size: calc(var(--bs-body-font-size) * 1.5);
    }

    p.lead {
        font-size: var(--bs-body-font-size);
    }
}

.dashboard-header {
    @media screen and (max-width: 450px) {
        .total-payments-money {
            font-size: calc(var(--bs-body-font-size) * 2.2) !important;
        }

        .total-payments-label {
            font-size: calc(var(--bs-body-font-size) * 0.875);
        }
    }
}

.filter-toggle-buttons {
    .btn > .badge {
        top: 0;
        --bs-badge-padding-y: 0.2625rem;
        --bs-badge-padding-x: 0.4875rem;
    }

    .btn.btn-sm {
        @media screen and (max-width: 450px) {
            font-size: 0.75rem;
        }
    }
}

.filter-tag {
    .btn-close {
        font-size: calc(var(--bs-body-font-size) * 0.65);
    }

    &.small {
        @media screen and (max-width: 450px) {
            font-size: 0.75em;
        }
    }
}

.app-list-controls {
    .form-control:focus,
    .form-check-input:focus {
        border-color: var(--bs-border-color);
        box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
    }

    .dropdown-toggle.btn-outline-secondary {
        --bs-btn-bg: var(--bs-white);
        --bs-btn-border-color: var(--bs-border-color);
        --bs-btn-hover-bg: var(--bs-primary);
        --bs-btn-active-bg: var(--bs-primary);
        --bs-btn-active-border-color: var(--bs-primary);
        --bs-btn-hover-border-color: var(--bs-primary);
        --bs-btn-focus-shadow-rgb: var(--bs-primary-rgb);

        &:focus {
            background-color: var(--bs-primary);
            border-color: var(--bs-primary);
            box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
        }
    }

    .dropdown-menu {
        --bs-dropdown-link-active-bg: var(--bs-primary);
    }
}

.apps-filter-accordion {
    .accordion-button {
        font-size: inherit;
        font-weight: inherit;
    }
}

.notification {
    min-height: 60px;
    padding: 20px 0;

    .notification-close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    .notification-icon {
        padding-top: 3px;
        width: 30px;
    }

    .notification-text {
        padding-right: 50px;
        line-height: 20px;
        font-size: 14px;
    }

    &--info-type {
        background: linear-gradient(to right, #e1eff8, #affafb);

        .notification-text {
            color: #000066;
        }

        #g1,
        #g2 {
            .color1 {
                stop-color: #000066;
            }
            .color2 {
                stop-color: #0099ff;
            }
        }

        .notification-icon {
            .info-icon {
                path {
                    fill: url(#g1);
                }
            }

            &--program {
                .info-icon {
                    path {
                        fill: url(#g2);
                    }
                }
            }
        }

        .notification-close-icon path {
            fill: #000066;
        }
    }

    &--attention-type {
        background: linear-gradient(to right, #f9d59e, #f9f9aa);

        .notification-text {
            color: #660000;
        }

        #g1,
        #g2 {
            .color1 {
                stop-color: #660000;
            }
            .color2 {
                stop-color: #ff6600;
            }
        }

        .notification-icon {
            .attention-icon {
                path {
                    fill: url(#g1);
                }
            }

            &--program {
                .attention-icon {
                    path {
                        fill: url(#g2);
                    }
                }
            }
        }

        .notification-close-icon path {
            fill: #660000;
        }
    }

    &--regular-type {
        background: linear-gradient(to right, #e8e8e8, #f6f6f6);

        .notification-text {
            color: #333333;
        }

        #g1,
        #g2 {
            .color1 {
                stop-color: #333333;
            }
            .color2 {
                stop-color: #cccccc;
            }
        }

        .notification-icon {
            .regular-icon {
                path {
                    fill: url(#g1);
                }
            }

            &--program {
                .regular-icon {
                    path {
                        fill: url(#g2);
                    }
                }
            }
        }

        .notification-close-icon path {
            fill: #333333;
        }
    }
}

.program-notification {
    box-shadow: inset 0px -8px 6px -6px rgba(0, 0, 0, 0.3);
}

.utility-notification {
    box-shadow: inset 0px -8px 6px -6px rgba(0, 0, 0, 0.3);
    z-index: 1;
}

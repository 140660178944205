@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.page-content {
    &.page-contact-us {
        .thank-you-text {
            max-width: 600px;
        }
    }

    &.page-contacts {
        .contact-blocks {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(520px, 1fr));
            grid-gap: 1rem;
            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            }
        }
    }

    .files-table {
        td {
            vertical-align: middle;
            padding: 0 10px;
        }
    }

    .customer-contact-list {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
        }
    }
}

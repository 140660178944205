@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.app-badge {
    border-radius: $border-radius-pill;
    border-width: $border-width;
    border-style: solid;

    &--tasks {
        --badge-bg-color: var(--bs-primary-bg);
        --badge-text-color: var(--bs-primary);
    }

    &--tasks-completed {
        --badge-bg-color: rgb(216, 240, 226);
        --badge-text-color: rgb(63, 152, 88);
    }

    background-color: var(--badge-bg-color);
    border-color: var(--badge-text-color);
    color: var(--badge-text-color);
}

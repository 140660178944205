.skip-to-main-content-link {
    opacity: 0;
    height: 0;
}

.skip-to-main-content-link:focus {
    position: relative;
    opacity: 1;
    width: 100%;
    height: 30px;
    padding: 5px;
}

.avatar {
    width: 2.5rem;
    height: 2.5rem;

    &--sm {
        width: 1.5rem;
        height: 1.5rem;
    }

    &--md {
        width: 2rem;
        height: 2rem;
    }

    &--blue {
        background: var(--bs-blue-200);

        .avatar-text {
            color: var(--bs-blue-700);
        }
    }

    &--purple {
        background: var(--bs-purple-200);

        .avatar-text {
            color: var(--bs-purple-700);
        }
    }

    &--pink {
        background: var(--bs-pink-200);

        .avatar-text {
            color: var(--bs-pink-700);
        }
    }

    &--orange {
        background: var(--bs-orange-200);

        .avatar-text {
            color: var(--bs-orange-800);
        }
    }

    &--teal {
        background: var(--bs-teal-200);

        .avatar-text {
            color: var(--bs-teal-800);
        }
    }

    &--cyan {
        background: var(--bs-cyan-200);

        .avatar-text {
            color: var(--bs-cyan-800);
        }
    }
}

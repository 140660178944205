@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Fira Sans";
    src: url("../fonts/fira_sans/FiraSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Fira Sans";
    src: url("../fonts/fira_sans/FiraSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Fira Sans";
    src: url("../fonts/fira_sans/FiraSans-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Fira Sans";
    src: url("../fonts/fira_sans/FiraSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Fira Sans";
    src: url("../fonts/fira_sans/FiraSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Fira Sans";
    src: url("../fonts/fira_sans/FiraSans-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Jost";
    src: url("../fonts/jost/Jost-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Jost";
    src: url("../fonts/jost/Jost-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Jost";
    src: url("../fonts/jost/Jost-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Jost";
    src: url("../fonts/jost/Jost-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Jost";
    src: url("../fonts/jost/Jost-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Jost";
    src: url("../fonts/jost/Jost-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/lato/Lato-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/open_sans/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/open_sans/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/open_sans/OpenSans-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/open_sans/OpenSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Overpass";
    src: url("../fonts/overpass/Overpass-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Overpass";
    src: url("../fonts/overpass/Overpass-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Overpass";
    src: url("../fonts/overpass/Overpass-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Overpass";
    src: url("../fonts/overpass/Overpass-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Overpass";
    src: url("../fonts/overpass/Overpass-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Overpass";
    src: url("../fonts/overpass/Overpass-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../fonts/raleway/Raleway-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../fonts/raleway/Raleway-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../fonts/raleway/Raleway-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../fonts/raleway/Raleway-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Raleway";
    src: url("../fonts/raleway/Raleway-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../fonts/raleway/Raleway-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/source_sans_pro/SourceSansPro-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/source_sans_pro/SourceSansPro-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/source_sans_pro/SourceSansPro-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/source_sans_pro/SourceSansPro-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("../fonts/source_sans_pro/SourceSansPro-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("../fonts/work_sans/WorkSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("../fonts/work_sans/WorkSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("../fonts/work_sans/WorkSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("../fonts/work_sans/WorkSans-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Work Sans";
    src: url("../fonts/work_sans/WorkSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("../fonts/work_sans/WorkSans-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Figtree";
    src: url("../fonts/figtree/Figtree-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Figtree";
    src: url("../fonts/figtree/Figtree-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Figtree";
    src: url("../fonts/figtree/Figtree-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Figtree";
    src: url("../fonts/figtree/Figtree-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Figtree";
    src: url("../fonts/figtree/Figtree-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Figtree";
    src: url("../fonts/figtree/Figtree-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Italianno";
    src: url("../fonts/Italianno/Italianno-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

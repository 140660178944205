@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.approved-equipment-details-list,
.equipment-categories {
    .list-group-item {
        color: var(--bs-body-color) !important;
    }

    .attribute-value {
        max-width: 50%;
        margin-left: auto;
    }

    // common mobile and tablet styles
    @include media-breakpoint-down(lg) {
        .list-group-item {
            flex-direction: column;
            .attribute-value {
                max-width: 100%;
                margin-top: 10px;
                margin-left: unset;
            }
        }
    }
}

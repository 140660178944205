@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.submitted-application-v2 {
    background: var(--bs-gray-100, #f7f7f9);

    .table {
        --bs-table-bg: transparent;
    }

    .powerbi-report {
        height: 80vh;
    }
}

$shadow-dark-right: inset 28px 0px 28px -38px rgba(0, 0, 0, 0.45);
$shadow-light-right: inset 28px 0px 28px -38px rgba(255, 255, 255, 0.45);
$shadow-dark-left: inset -28px 0px 28px -38px rgba(0, 0, 0, 0.45);
$shadow-light-left: inset -28px 0px 28px -38px rgba(255, 255, 255, 0.45);

.submitted-app-header-wrapper {
    .header-content {
        flex-shrink: 1;
        min-width: 0;
    }

    .print-button-icon {
        width: 1.25rem;
    }

    .contact-card {
        .collapse:not(.show) {
            display: block !important;
            height: 0;
            overflow: hidden;
        }
    }

    @include media-breakpoint-up(md) {
        .app-top-info-actions {
            margin-left: -1rem;
        }
    }
    .dropdown-menu.show {
        background-color: var(--bs-gray-100);
        a:hover {
            background: rgb(var(--bs-dark-rgb), 0.1);
        }
    }
    .navbar-nav {
        a.active {
            background-color: var(--bs-gray-100);
            border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-gray-100);
        }
    }

    &.dark-text {
        .breadcrumb .breadcrumb-item a,
        .breadcrumb .breadcrumb-item::before {
            color: var(--bs-dark);
        }
        button.dropdown-toggle {
            color: var(--bs-dark);
        }
    }
    &.white-text {
        .breadcrumb .breadcrumb-item a,
        .breadcrumb .breadcrumb-item::before {
            color: var(--bs-light);
        }
        button.dropdown-toggle {
            color: var(--bs-light);
        }
    }

    .app-info-grid-wrapper {
        background: var(--bs-gray-100);
        border-radius: 0.375rem;
        box-shadow: $box-shadow-sm;
        border: var(--bs-border-width) solid var(--bs-border-color-translucent);
        th,
        td {
            background-color: var(--bs-gray-100);
        }
    }

    .header-image {
        &.image-cover {
            object-fit: cover;
        }

        &.image-auto {
            object-fit: none;
        }
    }

    .application-header-nav::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .application-header-nav {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
    }
    .white-nav-shadow-right {
        box-shadow: $shadow-light-right;
    }
    .white-nav-shadow-left {
        box-shadow: $shadow-light-left;
    }
    .white-nav-shadow {
        box-shadow: $shadow-light-left, $shadow-light-right;
    }

    .black-nav-shadow-right {
        box-shadow: $shadow-dark-right;
    }
    .black-nav-shadow-left {
        box-shadow: $shadow-dark-left;
    }
    .black-nav-shadow {
        box-shadow: $shadow-dark-left, $shadow-dark-right;
    }
    .box-shadow-overlay {
        z-index: 1;
    }

    .application-header-nav-wrapper {
        flex: 1;
        white-space: nowrap;

        .dropdown-toggle {
            position: relative;
            display: flex;
            // hide default icon
            &::after {
                display: none;
            }
        }
    }
}

.application-contacts-list {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    }
}

.application-payments,
.application-equipment,
.application-files,
.application-correspondence {
    table,
    thead,
    tbody,
    tr,
    td {
        --bs-table-bg: transparent !important;
        background-color: transparent !important;
    }

    thead > tr {
        border-bottom: 2px solid var(--bs-body-color);
        box-shadow: none;
    }
}

.application-payments {
    table > thead > tr > th,
    table > tbody > tr > td::before {
        text-transform: none !important;
    }

    .total-amount-received {
        width: 26rem;

        .payments-card-image {
            width: 99px;
        }
    }

    @include media-breakpoint-down(md) {
        .avatar {
            height: 1.5rem;
            width: 1.5rem;

            .avatar-text {
                font-size: 0.8rem;
            }
        }

        .total-amount-received {
            width: 100%;

            .payments-card-image {
                width: 118px;
            }
        }
    }

    .payments-card-image {
        background-color: var(--bs-gray-200);
    }
}

.application-files {
    .download-file-button {
        svg {
            width: 16px;
        }
    }

    .mobile-files-block {
        border-bottom: 1px solid var(--bs-gray-400);
    }

    .folder-block {
        cursor: pointer;
        background: var(--bs-gray-200);

        .folder-block-icon {
            width: 27px;
        }

        .download-all-button {
            // same spacing as p-3p5
            right: $spacer * 1.25;
        }
    }
}

.application-relations {
    .data-grid {
        td {
            a {
                font-size: var(--btn-font-size);
            }
        }
    }
}

.category-icon {
    height: calc(var(--bs-body-font-size) * 1.8);
    width: calc(var(--bs-body-font-size) * 1.8);
    background-color: rgb(var(--bs-primary-rgb), 0.5);
    color: var(--bs-primary);
}

.offcanvas-body {
    .form-control,
    .form-control:focus,
    .form-select,
    .form-check-input,
    .list-group-item-action,
    .list-group-item {
        background-color: var(--bs-white);
    }

    .form-check {
        &.perform-task {
            display: block !important;

            .form-check-label {
                line-height: calc(var(--bs-body-font-size) * 1.3);
            }
        }

        .perform-task-error {
            right: 25px;
        }
    }
}

.app-empty-state {
    h3 {
        font-size: calc(var(--bs-body-font-size) * 1.75);
    }

    p.lead {
        font-size: calc(var(--bs-body-font-size) * 1.25);
    }
}

.social-links-widget-v1 {
    .social-links-widget-item {
        border: 1px solid;
        border-radius: 18px;
        padding: 0 15px 0 15px;
        min-width: 35px;
        height: 35px;
        cursor: pointer;

        .social-media-icon + .social-media-text {
            margin-left: 15px;
        }
    }
}

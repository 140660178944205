@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

@mixin firefox {
    @at-root {
        @-moz-document url-prefix() {
            & {
                @content;
            }
        }
    }
}

// for box shadow with overflow: auto
.data-grid-wrapper {
    .table-responsive {
        margin: -1rem;
        padding: 1rem;
    }
}

input.page-input::-webkit-outer-spin-button,
input.page-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input.page-input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.content-width-column {
    width: 0% !important;
}

#root,
.offcanvas {
    .data-grid {
        --bs-table-bg: var(--table-background-color);
        --table-border-color: #dee2e6;
        --table-header-background-color: var(--table-background-color);
        line-height: initial;
        margin-bottom: 0;
        border-color: var(--table-border-color);
        max-width: calc(100% - 1px); // To prevent 1px horizontal scroll on bordered table

        input[type="checkbox"] {
            height: 20px;
            width: 20px;

            &:checked {
                background-color: var(--radio-background-color-checked);
                border-color: var(--radio-border-color-checked);
            }
        }

        thead {
            &.sticky-top {
                box-shadow: 0 0 4px var(--bs-body-color);
                border: none;
            }

            > tr {
                display: none;
                border: none;

                @include media-breakpoint-up(lg) {
                    display: table-row;
                }

                > th {
                    border: none;
                    background-color: var(--table-header-background-color);
                    text-transform: capitalize;
                    vertical-align: middle;

                    svg {
                        color: var(--bs-body-color);
                    }

                    &:not(.hidden, .action-column) {
                        &:hover {
                            background-color: var(--bs-gray-200) !important;
                            cursor: pointer;
                        }
                    }

                    &:not(:hover, .sorted) {
                        svg {
                            visibility: hidden;
                        }
                    }
                }
            }
        }

        tbody {
            > tr {
                display: flex;
                flex-direction: column;
                border: none;
                background-color: var(--table-background-color);
                border-radius: $border-radius;
                box-shadow: $box-shadow-sm;
                margin-top: map-get($spacers, 3);
                margin-bottom: map-get($spacers, 3);
                padding: map-get($spacers, 2);

                @include media-breakpoint-up(lg) {
                    border-radius: 0;
                    box-shadow: none;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding: 0;
                    display: table-row;
                }

                > td {
                    display: flex;
                    flex-direction: column;
                    border: 0;
                    vertical-align: middle;

                    @include media-breakpoint-down(lg) {
                        &::before {
                            content: attr(data-label);
                            text-transform: uppercase;
                            font-weight: 500;
                        }

                        &.actions-cell {
                            padding: 0;
                            flex-direction: row-reverse;
                            justify-content: space-between;

                            &::before {
                                display: none;
                            }
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        display: table-cell;

                        // Make action table cell only take as much space as its content
                        &.actions-cell {
                            width: 1%;
                            white-space: nowrap;
                        }

                        .btn-link {
                            vertical-align: baseline;
                            padding: 0;
                            line-height: 1;
                        }
                    }

                    @include media-breakpoint-down(lg) {
                        &.actions-cell {
                            padding-top: 10px;
                            border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
                        }
                    }
                }
            }
        }

        tfoot {
            > tr {
                border-width: 0;
                border-color: var(--table-border-color);
                display: flex;
                flex-direction: column;
                background-color: var(--table-background-color);
                padding: map-get($spacers, 2);

                @include media-breakpoint-up(lg) {
                    border-top-width: 1px;
                    display: table-row;
                }

                > th {
                    font-size: 0.75rem;
                    color: var(--label-color);
                    border: none;
                    background-color: var(--table-header-background-color);
                    text-transform: uppercase;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            border-width: 0;

            td.hide-mobile-label {
                &::before {
                    display: none;
                }

                .app-fav-icon {
                    margin-left: auto;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &.data-grid {
                box-shadow: $box-shadow-sm;

                &:not(.compact) {
                    tr > th,
                    tr > td {
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                    }
                }
            }
        }

        .sticky-column-right {
            position: sticky;
            right: 0;
            box-shadow: inset 2px 0 0 var(--bs-body-color);
            min-width: 7rem;
            text-align: center;
        }

        .col-sorted {
            background-color: var(--table-header-background-color);
        }

        .data-grid-filter {
            .form-control {
                min-width: 60px;
            }
        }

        &.approved-equipment-list {
            tbody > tr > td {
                @include media-breakpoint-up(lg) {
                    &:not(.actions-cell) {
                        padding-left: 18px !important;
                    }
                }
            }
        }
    }
}

.data-grid-in-modal {
    &.table {
        @include media-breakpoint-down(lg) {
            tbody {
                tr {
                    margin: 0 1rem 1rem;
                }
            }
        }
    }
}

.data-grid-column-sort {
    border: none;
    background: transparent;
    color: var(--bs-body-color);
}

.data-grid-active-filter-chips {
    .badge {
        span {
            overflow: hidden;
            max-width: 20ch;
            text-overflow: ellipsis;
        }
    }
}

.data-grid-filter-chips-container {
    .select-all-checkbox {
        line-height: calc(var(--bs-body-font-size) * 1.2);

        input[type="checkbox"] {
            height: 20px;
            width: 20px;

            &:checked {
                background-color: var(--radio-background-color-checked);
                border-color: var(--radio-border-color-checked);
            }
        }
    }
}

.relations-panel {
    .table-responsive {
        overflow-wrap: anywhere;
    }
}

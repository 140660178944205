@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.submitted-app-messages-list {
    min-height: 25rem;

    @include media-breakpoint-up(md) {
        max-height: 29rem;
    }

    background: var(--bs-gray-200, #e8e8f0);

    .submitted-app-messages-list-item {
        max-width: 36rem;
    }
}

.submitted-app-messages-input {
    background: var(--bs-gray-200, #e8e8f0);
}

.app-correspondence-empty-state {
    max-width: 32rem;
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.contractor-search-dropdown {
    max-height: 400px;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    @include media-breakpoint-up(md) {
        max-height: 600px;
    }
}

.gm-style .gm-style-iw {
    font-weight: inherit;
    font-size: "var(--bs-body-font-size)" !important;
}

.gm-style-iw-d {
    padding: 0 !important;
}

.scrollbar-offset::-webkit-scrollbar {
    visibility: hidden;
}
.scrollbar-offset {
    flex-shrink: 0;
    overflow-y: scroll;
}

.page-contractor-search {
    background: var(--bs-gray-100, #f7f7f9) !important;
}

.contractor-map-view {
    .btn-as-dropdown {
        border: var(--bs-border-width) solid var(--bs-border-color) !important;
        border-radius: var(--bs-border-radius) !important;
    }

    .gm-style-iw-tc {
        display: none;
    }

    .sidebar-buttons-mobile {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
        background: var(--bs-gray-100, #f7f7f9);
    }
    .list-container {
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 450px;
        }
    }

    @include media-breakpoint-up(md) {
        .map-inner-container {
            flex: 1;
        }
    }

    .full-screen-map {
        z-index: 1;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }

    .list-button-container {
        top: 0;
        button {
            flex: 1;
        }
    }

    .map-top-buttons-desktop {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        button {
            background-color: white !important;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px !important;
            color: rgb(102, 102, 102) !important;
            border-radius: 2px;
            min-width: 40px;
        }
    }

    .map-top-buttons {
        z-index: 1;
        background: var(--bs-gray-100, #f7f7f9);
        border-bottom: 1px solid grey;
    }

    .scrollbox {
        overflow-y: scroll;
    }

    .marker-popup {
        width: 362px;
        font-weight: 400;
        font-size: var(--bs-body-font-size) !important;
        line-height: var(--bs-body-line-height) !important;
        font-weight: var(--bs-body-font-weight) !important;
        font-family: var(--bs-body-font-family) !important;
        .contractor-card {
            padding: 0 !important;
        }
    }

    .main-container {
        height: 850px;
        @include media-breakpoint-down(md) {
            height: 100%;
        }
    }

    .collapse-tag-container {
        z-index: 1;
        background: var(--bs-gray-100, #f7f7f9);
        div {
            cursor: pointer;
        }
    }
    .show-filter-tags-button {
        cursor: pointer;
    }
}
